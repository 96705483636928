import React, {useEffect} from 'react';
import './about.scss'
import {translation, dispatch} from "../../helpers";
import {setLoader} from "../../redux/slices/global/globalSlice";

const About = () => {

    useEffect(() => {
        dispatch(setLoader(false))
    }, []);

    return (
        <main className='about container'>
            <div className='about_container'>
                <h1 className='about_title'>Armenian Science Citation Index (ASCI)</h1>
                <p className='about_text'>The Armenian Science Citation Index (ASCI) is a comprehensive bibliographic database
showcasing scientific publications from Armenia, serving as a central hub for researchers,
institutions, and publishers. Developed since 2020 by the <a href="http://csiam.sci.am/en/" target="_blank" rel="noopener noreferrer">Center for Scientific Information
Analysis and Monitoring</a> at the <a href="https://iiap.sci.am/" target="_blank" rel="noopener noreferrer">Institute for Informatics and Automation Problems (IIAP),
National Academy of Sciences of Armenia</a>, ASCI represents a significant milestone in
Armenia’s scientific landscape.</p>
                <p className='about_text'>ASCI offers a robust suite of services tailored to meet the needs of authors, research institutions,
and scientific publishers. Beyond its primary function of providing seamless access to
bibliographic data, ASCI is designed as a powerful analytical tool for evaluating the impact and
performance of research organizations, individual scientists, and academic journals.</p>
                <p className='about_text'>The subject categories are classified into 15 scientific fields, according to the classifications
provided by <a href="https://link.springer.com/article/10.1023/A:1022378804087" target="_blank" rel="noopener noreferrer">Glänzel and Schubert (2003)</a>.</p>
                <p className='about_text'>This project is supported by the Higher Education and Science Committee (MESCS RA) under
grant number 20TTCG-5I013, demonstrating the nation&#39;s commitment to advancing scientific
excellence and visibility.</p>
                <p className='about_text'><b>Future Vision</b><br />
ASCI aims to position Armenian research within the global scientific ecosystem by pursuing
integration with leading international databases. This will enhance the visibility of Armenian
science, fostering international collaboration and elevating the global impact of our researchers.</p>
<p className='about_text'>Join us in shaping the future of Armenian science by leveraging ASCI as a bridge between local
research and the global scientific community.</p>
            </div>
        </main>
    );
};

export default About;
