import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";

import i18n from "../../i18next/i18next";
import {dispatch, translation} from "../../helpers";
import Logo from './../../assets/images/ASCI-Logo.png'
import {globalSelector, resetStore, setLocale} from "../../redux/slices/global/globalSlice";
import {useTranslation} from "react-i18next";

function Header() {
    const [localStorageCleared, setLocalStorageCleared] = useState(false);
    const { i18n: {language}} = useTranslation();
    const {locale} = useSelector(globalSelector);
    const {pathname, search, hash} = useLocation();
    const history = useHistory();

    function changeLang(lang) {
        i18n.changeLanguage(lang);
        dispatch(setLocale(lang));
        window.location.replace(
            `/${lang}${pathname}${search}${hash}`
        );
    }

    useEffect(() => {
        const handleStorageChange = () => {
            if (localStorage.length === 0) {
                setLocalStorageCleared(true);
                history.push('/');
            }
        };

        window.addEventListener('storage', handleStorageChange);

        if (typeof Storage !== 'undefined') {
            if(!localStorage.getItem('searchObj')){
                localStorage.setItem("searchObj", JSON.stringify({}));
                localStorage.setItem("lang", language === "en" ? "eng" : "arm")
            }

            if (!localStorage.getItem('lang') || !localStorage.getItem('searchObj')) {
                setLocalStorageCleared(true);
                dispatch(resetStore());
                history.push('/');
            }
        } else {
            console.log('Local storage is not supported by the browser');
        }

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [localStorageCleared]);

    return (
        <header className="header">
            <div className='container'>
                <div className='tb--flex tb--align-center'>
                    <Link to="/"><img className='logo' src={Logo} alt='logo'/></Link>
                    <nav className='menu'>
                        <ul className='menu_container'>
                            <li className='menu_item'><Link to='/about'
                                                            className='menu_link'>{translation('About')}</Link></li>
{/*                            
<li className='menu_item'><Link to='/guide-lines'
                                                            className='menu_link'>{translation('Guidelines')}</Link>
                            </li>
*/}
                            <li className='menu_item'><Link to='/contact-us'
                                                            className='menu_link'>{translation('Contact Us')}</Link>
                            </li>
                        </ul>
                    </nav>
                    {/* <div className='lang-container'>
                        <button
                            className={locale === 'en' ? 'lang-button active' : 'lang-button'}
                            type='button'
                            onClick={() => changeLang('en')}>
                            {translation('ENG')}
                        </button>
                        <span className='lang-line'>&nbsp;/&nbsp;</span>
                        <button
                            className={locale === 'am' ? 'lang-button active' : 'lang-button'}
                            type='button'
                            onClick={() => changeLang('am')}>
                            {translation('ARM')}
                        </button>
                    </div> */}
                </div>
            </div>

        </header>
    );
}

export default Header;
