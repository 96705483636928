import React, {useEffect} from 'react';
import './contactUs.scss'
import {dispatch, translation} from "../../helpers";
import {setLoader} from "../../redux/slices/global/globalSlice";

const ContactUs = () => {

    useEffect(() => {
        dispatch(setLoader(false))
    }, []);

    return (
        <main className='contact container'>
            <div className='contact_container'>
                <h1 className='contact_title'>{translation('Contact Us')}</h1>
                <a href='mailto:info@asci.am' className='contact_link'>csiam@ipia.sci.am</a>
                <a href='tel:+374 12 345 678 ' className='contact_link'>+374 60 62 35 53</a>
                <address className='contact_link'>1 Paruyr Sevak str. 0014 Yerevan, Armenia</address>
<iframe
    className='contact_map'
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1440.6715493469771!2d44.5327275197438!3d40.21260182443732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa2c8c143a0cd%3A0x72dffbe489313480!2sInformation%20and%20Automation%20Problems%20of%20The%20National%20Academy%20of%20Sciences%20Institute!5e1!3m2!1sru!2sam!4v1733349266317!5m2!1sru!2sam"
    width="600"
    height="450"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
></iframe>
            </div>
        </main>
    );
};

export default ContactUs;
